.BlogPage {
  max-width: 980px;
  padding: 40px 40px 60px 40px;
  margin: 0 auto;

}

.BlogPage > h1 {
  font-size: 36px;
  font-weight: 500;
  margin: 0 0 32px 0;
  letter-spacing: -.5px;
  color: var(--grayBase);
}

.BlogPosts {
  list-style: none;
  padding-left: 0;
}

.BlogPosts__list {
  margin-bottom: 40px;
}

.BlogPosts__list > a {
  text-decoration: none;
  color: inherit;
  margin-bottom: 40px;
}

.BlogPosts__list > a:hover h2 {
  text-decoration: underline;
}

.BlogPosts__tags {
  list-style: none;
  padding: 8px 0 0 0;
}

.BlogPosts__tag {
  display: inline-block;
  background-color: var(--grayLightestBg);
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  color: var(--grayLight);
}

.BlogPosts__body {
  display: flex;
}

.BlogPosts__thumbnail {
  margin: 0;
  padding-top: 4px;
  padding-right: 32px;
  width: 180px;
}

.BlogPosts__mobile-thumbnail {
  display: none;
}

.BlogPosts__thumbnail > img, .BlogPosts__mobile-thumbnail > img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.BlogPosts__text {
  flex: 1;
}

.BlogPosts__title {
  font-weight: 500;
  color: var(--grayBase);
  letter-spacing: -.3px;
  margin: 0 0 4px 0;
  line-height: 125%;
}

.BlogPosts__summary {
  font-weight: 400;
  color: var(--grayLight);
  line-height: 140%;
  margin: 0;
}

.BlogPosts__timestamp {
  margin-top: 8px;
  line-height: 150%;
  font-size: 14px;
  color: var(--grayLighter);
}

@media screen and (max-width: 800px) {
  .BlogPage {
    padding-top: 0;
  }
}

@media screen and (max-width: 800px) {
  .BlogPage {
    padding: 0;
  }

  .BlogPosts__thumbnail {
    display: none;
  }

  .BlogPosts__list h2 {
    display: table;
    font-size: 22px;
    line-height: 120%;
  }

  .BlogPosts__list h2 > span {
    display: table-cell;
    vertical-align: top;
  }

  .BlogPosts__mobile-thumbnail {
    width: 80px;
    padding-top: 4px;
    padding-right: 16px;
    display: table-cell;
  }
}