.ContactCard {
  width: 450px;
  height: 274px;
  border-radius: 8px;
  box-shadow: 16px 16px 40px rgba(0, 0, 0, 0.1);
  padding: 30px 20px 20px 20px;
  position: relative;
  background-image: linear-gradient(135deg, rgb(255, 255, 255), rgb(248, 248, 248));
}

.ContactCard__header {
  color: var(--grayBase);
  font-weight: var(--fontWeightMedium);
  font-size: 24px;
  letter-spacing: -.3px;
  margin: 0;
}

.ContactCard__subheader {
  color: var(--grayLighter);
  margin: 8px 0 0 0;
}

.ContactCard__qr-container {
  position: absolute;
  top: 24px;
  right: 24px;
  margin: 0;
}

.ContactCard__qr {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  box-shadow: 4px 6px 12px rgba(0, 0, 0, 0.1);
}

.ContactCard__table {
  border-spacing: 0;
  position: absolute;
  bottom: 24px
}

.ContactCard__table a {
  color: var(--grayLight);
  text-decoration: none;
}

.ContactCard__table a.ContactCard__email {
  color: var(--linkColor);
  text-decoration: none;
}

.ContactCard__table p {
  color: var(--grayLighter);
  margin: 0;
}

.ContactCard__table td {
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  padding-top: 8px;
}

@media screen and (max-width: 800px) {
  .ContactCard {
    width: 274px;
    height: 400px;
    margin: 0 auto;
  }

  .ContactCard__header {
    color: var(--grayBase);
  }

  .ContactCard__qr-container {
    position: relative;
    left: 0;
    top: 0;
    margin: 20px 0 0 0;
  }

  .ContactCard__qr {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    box-shadow: 4px 6px 12px rgba(0, 0, 0, 0.1);
  }

  .ContactCard__table {
    position: relative;
    bottom: 0;
    margin: 70px 0 0 0;
  }

  .ContactCard__table td {
    display: block;
    width: 100%;
    margin-top: 0;
  }
}