.Error404Page {
  max-width: 480px;
  padding: 96px 40px 0 40px;
  min-height: calc(100vh - 80px - 216px - 40px);
  margin: 0 auto;
  text-align: center;
}

.Error404Page__header {
  font-size: 128px;
  font-weight: 500;
  margin: 0;
  letter-spacing: -5px;
  color: var(--grayBase);
  padding-right: 5px;
}

.Error404Page__header-description {
  font-size: 56px;
  font-weight: 400;
  margin: 0;
  letter-spacing: -1px;
  color: var(--grayLighter);
}

.Error404Page__header-action {
  font-size: 18px;
  max-width: 480px;
  font-weight: 400;
  margin: 48px 0 0 0;
  color: var(--grayBase);
  line-height: 150%;
}

@media screen and (max-width: 800px) {
  .Error404Page {
    padding-top: 40px;
    margin: 0 auto;
    padding-bottom: 200px;
  }

  .Error404Page__header {
    font-size: 80px;
    font-weight: 500;
    margin: 0;
    letter-spacing: -2px;
    color: var(--grayBase);
    display: block;
  }

  .Error404Page__header-description {
    font-size: 40px;
    font-weight: 500;
    margin: 0;
    letter-spacing: -0.6px;
    color: var(--grayLighter);
  }
}
