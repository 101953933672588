.BlogDetailPage {
  padding-top: 40px;
  padding-bottom: 120px;
}

.BlogPost__article {
  font-size: 20px;
  padding: 0 40px;
  max-width: 960px;
  margin: 0 auto;
}

.BlogPost__breadcrumbs {
  font-size: 20px;
  padding: 0 40px;
  max-width: 960px;
  margin: 0 auto;
}

.BlogPost__breadcrumbs > ul {
  list-style: none;
  padding-left: 0;
}

.BlogPost__breadcrumb {
  display: inline-block;
  color: var(--grayLight);
}

.BlogPost__breadcrumb > a:hover {
  text-decoration: underline;
}

.BlogPost__breadcrumb::after {
  content: '→';
  color: var(--grayLightest);
  padding: 0 8px;
}

.BlogPost__breadcrumb--active {
  color: var(--grayLightest);
}

.BlogPost__breadcrumb--active::after {
  content: '';
}

.BlogPost__breadcrumb a {
  text-decoration: none;
  color: inherit;
}

.BlogPost__header {
  padding-bottom: 30px;
  margin-bottom: 36px;
  border-bottom: solid 1px var(--grayLightest);
}

.BlogPost__heading {
  font-family: var(--fontHeader);
  margin: 0 0 16px 0;
  font-size: 40px;
  font-weight: 500;
  color: var(--grayBase);
  letter-spacing: -0.5px;
}

.BlogPost__summary {
  font-family: var(--fontHeader);
  margin: 0 0 16px 0;
  font-size: 20px;
  font-weight: 400;
  color: var(--grayLight);
  line-height: 140%;
}

.BlogPost__timestamp {
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: 300;
  color: var(--grayLighter);
  line-height: 150%;
}

.BlogPost__content {
  font-family: var(--fontBody);
}

.BlogPost__content > h2 {
  font-family: var(--fontHeader);
  color: var(--grayBase);
  font-size: 32px;
  line-height: 125%;
  font-weight: 500;
  margin: 48px 0 16px 0;
}

.BlogPost__content > h3 {
  font-family: var(--fontHeader);
  color: var(--grayLight);
  font-size: 24px;
  line-height: 125%;
  font-weight: 500;
  margin: 24px 0 12px 0;
}

.BlogPost__content > ul {
  color: var(--grayBase);
  font-size: 20px;
  line-height: 150%;
  margin: 0 0 8px 0;
  list-style: none;
  padding-left: 20px;
}

.BlogPost__content > ul > li {
  text-indent: -20px;
}

.BlogPost__content > ul > li::before {
  content: '–';
  padding-right: 10px;
  width: 40px;
  text-indent: -5px;
}

.BlogPost__content code {
  font-family: var(--fontMono);
  background-color: var(--grayLightestBg);
  padding: 0 4px;
  font-size: .9em;
  color: var(--grayBase);
}

.BlogPost__content figure {
  padding: 32px 0;
}

.BlogPost__content img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 12px -4px rgba(0, 0, 0, 0.3);
}

.BlogPost__content blockquote {
  font-size: 20px;
  line-height: 150%;
  color: var(--grayLight);
  margin: 24px 0 32px 16px;
  border-left: solid 3px var(--brandColor);
  padding-left: 16px;
}

.BlogPost__content figcaption {
  font-size: 16px;
  font-weight: 600;
  color: var(--grayLighter);
  text-align: center;
  padding: 12px 0;
}

.BlogPost__content > p {
  font-family: var(--fontArticle);
  color: var(--grayBase);
  font-size: 20px;
  line-height: 150%;
  margin: 0 0 8px 0;
  font-weight: 400;
}

.BlogPost__footer {
  padding-top: 60px;
  font-size: 18px;
  color: var(--grayLighter);
}

.BlogPost__footer > p {
  margin: 0 0 8px 0;
}

.BlogPost__footer-navigations {
  display: flex;
  padding: 120px 0 60px 0;
}

.BlogPost__footer-nav {
  flex: 1 1 50%;
}

.BlogPost__footer-nav a {
  text-decoration: none;
  color: inherit;
}

.BlogPost__footer-nav:hover {
  background-color: var(--grayLightestBg);
  transition: background-color 300ms ease-in-out;
}

.BlogPost__footer-nav-arrow-column > p {
  transition: margin 300ms ease-in-out;
}

.BlogPost__footer-nav--right {
  text-align: right;
}

.BlogPost__footer-nav:hover .BlogPost__footer-nav-arrow-column > p {
  margin-left: -8px;
}

.BlogPost__footer-nav--right:hover .BlogPost__footer-nav-arrow-column > p {
  margin-right: -8px;
}

.BlogPost__footer-nav-inner-table {
  width: 100%;
  border-spacing: 0;
  padding: 24px 0 30px 0;
}

.BlogPost__footer-nav-inner-table td {
  vertical-align: top;
  padding: 0 16px;
}

.BlogPost__footer-nav-indicator {
  font-size: 20px;
  font-weight: 500;
  color: var(--grayLighter);
  padding-bottom: 8px;
}

.BlogPost__footer-nav-title {
  font-size: 24px;
  font-weight: 400;
  color: var(--grayLight);
  line-height: 135%;
}

.BlogPost__footer-nav-inner-table .BlogPost__footer-nav-arrow-column {
  width: 60px;
  font-size: 30px;
  vertical-align: middle;
  color: var(--grayBase);
}

@media screen and (max-width: 800px) {
  .BlogDetailPage {
    padding-top: 0;
  }

  .BlogPost__article {
    padding: 0;
  }

  .BlogPost__breadcrumbs {
    padding: 0 0 24px 0;
    font-size: 16px;
  }

  .BlogPost__breadcrumbs > ul {
    margin: 0;
  }

  .BlogPost__heading {
    margin: 0 0 16px 0;
    font-size: 28px;
  }

  .BlogPost__summary {
    margin: 0 0 16px 0;
    font-size: 20px;
    font-weight: 400;
  }

  .BlogPost__content > p {
    font-size: 16px;
  }

  .BlogPost__content > h2 {
    font-size: 24px;
    margin: 40px 0 12px 0;
  }

  .BlogPost__content > h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 24px 0 8px 0;
  }

  .BlogPost__content > ul {
    font-size: 16px;
  }

  .BlogPost__timestamp {
    margin: 0 0 8px 0;
    font-size: 16px;
  }

  .BlogPost__footer-navigations {
    display: block;
    padding: 30px 0;
  }

  .BlogPost__footer-nav {
    flex: none;
  }

  .BlogPost__content figcaption {
    font-size: 14px;
  }

  .BlogPost__content blockquote {
    font-size: 18px;
    margin-left: 0;
  }

  .BlogPost__footer-nav-indicator {
    font-size: 18px;
    font-weight: 500;
    color: var(--grayLighter);
    padding-bottom: 8px;
  }

  .BlogPost__footer-nav-title {
    font-size: 20px;
    font-weight: 400;
    color: var(--grayLight);
    line-height: 135%;
  }
}