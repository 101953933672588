.NavigationBar {
  padding: 0 40px;
  display: flex;
  position: fixed;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  width: 100%;
}

.NavigationBar__offset {
  padding-top: 120px;
}

.NavigationBar__header-container {
  font-size: 24px;
  text-decoration: none;
  font-weight: var(--fontWeightBold);
  flex: 0 1 240px;
  position: relative;
  align-items: center;
  display: flex;
  transform-origin: left;
}

.NavigationBar__header {
  color: var(--brandColor);
  transition: transform 0.5s ease-in-out;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.NavigationBar__header > span {
  display: block;
  text-transform: lowercase;
  overflow: hidden;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: -4px;
}

.NavigationBar__header:hover {
  color: var(--brandColor);
}

.NavigationBar__link-container {
  flex: 0 0;
  margin-right: 60px;
  align-items: center;
  display: flex;
}

.NavigationBar__link {
  text-decoration: none;
  font-size: 20px;
  color: black;
  font-weight: var(--fontWeightMedium);
  display: inline-block;
}

.NavigationBar__link--active {
  color: var(--grayLighter);
  cursor: default;
}

.NavigationBar__link:hover {
  color: black;
}

.NavigationBar__link--active:hover {
  color: var(--grayLighter);
}

@media screen and (max-width: 800px) {
  .NavigationBar {
    padding: 0 16px;
    display: flex;
  }

  .NavigationBar__offset {
    padding-top: 80px;
  }

  .NavigationBar__header-container {
    font-size: 20px;
    text-decoration: none;
    font-weight: var(--fontWeightBold);
    height: 80px;
    display: flex;
    flex-basis: 160px;
  }

  .NavigationBar__link-container {
    margin-right: 40px;
  }

  .NavigationBar__link {
    font-size: 16px;
  }
}
