.App * {
  box-sizing: border-box;
}

.Main {
  min-height: 100vh;
}

a {
  color: var(--linkColor);
}

a:hover {
  color: var(--linkColorHover);
}

@media screen and (max-width: 800px) {
  .Main {
    display: block;
  }

  .Sidebar {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;
    height: auto;
    padding: 20px;
  }

  .Content {
    height: auto;
    overflow: auto;
    padding: 30px 20px 20px 20px;
  }
}

:root {
  --brandColor: rgb(255, 96, 0);

  --brandColorTransparent: rgba(255, 96, 0, .1);

  --grayBase: rgb(48, 48, 48);
  --grayLight: rgb(96, 96, 96);
  --grayLighter: rgb(140, 140, 140);
  --grayLightest: rgb(192, 192, 192);

  --grayLightestBg: rgb(242, 242, 242);

  --linkColor: rgb(0, 122, 255);
  --linkColorHover: rgb(48, 192, 255);

  --mobileBreakpoint: 800px;

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightBold: 600;

  --fontHeader: tenon, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  --fontBody: tenon, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  --fontArticle: tenon, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  --fontMono: input-mono, monospace;

  /*--columnWidth: 89;*/
  /*--gutterPadding: 40;*/
  /*--columnSpacing: 12;*/
}
