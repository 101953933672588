.Footer {
    display: block;
    color: var(--grayLighter);
    background-color: var(--grayLightestBg);
    padding: 16px 40px 32px 40px;
}

.Footer__sitemap {
    text-align: left;
    padding: 8px 0 24px 0;
    max-width: 980px;
    margin: 0 auto;
}

.Footer__sitemap > p {
    font-size: 20px;
    font-weight: 500;
    color: var(--grayLight);
    margin-bottom: 16px;
}

.Footer__sitemap > ul {
    list-style: none;
    padding-left: 0;
}

.Footer__sitemap-link-container {
    margin: 0 0 8px 0;
}

.Footer__sitemap-link {
    text-decoration: none;
    color: var(--grayLight);
}

.Footer footer {
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    max-width: 980px;
    margin: 0 auto;
}

@media screen and (max-width: 800px) {
    .Footer {
        padding: 8px 20px 32px 20px;
    }
}