.AboutPage {
  padding: 96px 40px 0 40px;
  min-height: calc(100vh - 80px - 216px - 64px);
}

.AboutPage__header {
  font-size: 36px;
  font-weight: 500;
  margin: 0 auto 32px auto;
  letter-spacing: -.5px;
  color: var(--grayBase);
}

.AboutPage__container {
  max-width: 980px;
  margin: 0 auto 24px auto;
  position: relative;
  padding-right: 480px;
}

.AboutPage__text {
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 16px 0;
  color: var(--grayLight);
  line-height: 140%;
  max-width: 450px;
}

.AboutPage__footer {
  margin-top: 60px;
  font-size: 18px;
  color: var(--grayLighter);
  line-height: 150%;
}

.AboutPage__contact-container {
  position: absolute;
  top: -8px;
  right: -20px;
  transform: rotate(3deg) scale(0.95);
  transition: all 0.5s ease-in-out;
}

.AboutPage__contact-container--mobile {
  display: none;
}

.AboutPage__contact-offset {
  display: none;
}

.AboutPage__social-container {
  padding-top: 16px;
}

.AboutPage__social {
  font-size: 28px;
  margin-right: 8px;
  color: var(--grayLighter);
  width: 48px;
  height: 48px;
  background-color: var(--grayLightestBg);
  display: inline-block;
  text-align: center;
  line-height: 52px;
  border-radius: 24px;
}

.AboutPage__body-text {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 800px) {
  .AboutPage {
    padding: 0 0 60px 0;
    min-height: calc(100vh - 80px - 216px - 24px);
  }

  .AboutPage__header {
    font-size: 32px;
  }

  .AboutPage__text {
    max-width: inherit;
  }

  .AboutPage__container {
    padding-right: 0;
  }

  .AboutPage__contact-offset {
    display: block;
    padding-top: 480px;
  }

  .AboutPage__contact-container {
    display: none;
  }

  .AboutPage__contact-container--mobile {
    display: block;
    position: fixed;
    top: 120px;
    right: 0;
    left: 0;
    transition: none;
  }
}
